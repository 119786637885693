<template>
  <div v-if="showHeader" class="header">
    <div class="header-img">
      <img
        class="gg-logo"
        @click="goBack"
        src="../../assets/image/mobile/index/logo.png"
        alt=""
      />
      <img
        v-if="!show"
        class="new-list"
        src="../../assets/image/mobile/index/nav.png"
        alt=""
        @click="listShow"
      />
      <span v-if="show" class="order" @click="listClose">×</span>
    </div>
    <ul v-if="show" id="aside" class="aside">
      <li class="home" @click="homeClick">首页</li>
      <li class="about" @click="aboutClick">关于我们</li>
      <li class="recruitment" @click="recruitmentClick">人才招聘</li>
      <li class="new" @click="newClick">新闻资讯</li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      i: 0,
      show: false,
      height: "",
      showHeader: true
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    // 监听（绑定）滚轮 滚动事件
  },
  methods: {
    handleScroll() {
      // 页面滚动距顶部距离
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      var scroll = scrollTop - this.i;
      if (scroll < 2.5) {
        this.showHeader = true;
      } else {
        this.showHeader = false;
      }
      this.i = scrollTop;
    },
    listShow() {
      this.show = !this.show;
    },
    listClose() {
      this.show = !this.show;
    },
    homeClick() {
      if (window.location.pathname == "/") {
        this.$router.go(0);
      } else {
        this.$router.push({ path: "/" });
      }
    },
    aboutClick() {
      if (window.location.pathname == "/about.html") {
        this.$router.go(0);
      } else {
        this.$router.push({ path: "../about.html" });
      }
    },
    recruitmentClick() {
      if (window.location.pathname == "/career") {
        this.$router.go(0);
      } else {
        this.$router.push({ path: "../career" });
      }
    },
    newClick() {
      if (window.location.pathname == "/newsdetails") {
        this.$router.go(0);
      } else {
        this.$router.push({ path: "../newsdetails" });
      }
    },
    goBack() {
      this.$router.push({ path: "/" });
    }
  }
};
</script>

<style scoped>
.header {
  position: fixed;
  z-index: 9999;
  /* opacity: .9; */
}
.header .header-img {
  width: 23.4375rem;
  height: 3.575rem;
  background-color: #fff;
}
.header .header-img .gg-logo {
  width: 7.375rem;
  height: 2.0625rem;
  position: absolute;
  top: 0.9375rem;
  left: 1.125rem;
}
.header .header-img .new-list {
  width: 1.25rem;
  height: 0.925rem;
  position: absolute;
  top: 1.375rem;
  right: 1.1875rem;
  cursor: pointer;
}
.header .header-img .order {
  position: fixed;
  top: 0;
  right: 1.5rem;
  font-size: 2.65rem;
  color: #fff;
  z-index: 9999;
}
.header .aside {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(128, 126, 126, 0.9);
  z-index: 99;
}
.aside li {
  line-height: 4.6rem;
  text-align: center;
  border-bottom: 1px solid #ccc;
  font-size: 1.25rem;
  color: #fff;
  cursor: pointer;
}
</style>
