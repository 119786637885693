<template>
  <div class="us">
    <Head />
    <div class="main">
      <div class="detail_top">
        <span class="en">
          <p>THE BEST TEAMS</p>
          <p>MAKE THE BEST GAMES</p>
        </span>
        <p class="zh">最好的团队制作最好的游戏</p>
      </div>
      <div class="detail_middle">
        <p class="our-story">我们的故事</p>
        <div class="content">
          <p>
            上海域起网络科技有限公司成立于 2013年，始终秉承“通过科技和艺术改变世界”的文化使命，是一家集游戏研发和发行于一体的高新技术企业，2020年被评定为浦东新区企业研发机构、浦东新区高成长性总部，并在2021年成为上海市第一批重点机构中的一员。
          </p>
          <p>
            公司拥有5A级办公环境，积极推崇硅谷文化。公司规模近400人，核心成员曾服务于华为、腾讯、网易、莉莉丝等多家知名企业。公司自主研发并发行了《GG游戏大厅》、《Garden Affairs》等多款游戏，并于近年分别独家代理了以二次元“东方Project”为原型的二次创作手游《东方LostWord》、由国内知名团队研发的Match-3手游《Home Makeover》等，在全球拥有上亿注册用户。
          </p>
          <p>
            通过自主研发、发行与代理发行并行，域起网络未来将开拓更多领域，进一步完善公司产业线布局，立足全球化视野，开拓产业发展战略新步伐。
          </p>
        </div>
      </div>
      <div class="detail_hope">
        <p class="our-hope">我们的愿景与使命</p>
        <ul class="banner">
          <li class="hope">
            <p class="detail_title">愿景</p>
            <p>成为世界级的公司</p>
          </li>
          <li class="mission">
            <p class="detail_title">使命</p>
            <p>通过科技与艺术的结合</p>
            <p>创造出用户喜爱的产品</p>
          </li>
          <li class="values">
            <p class="detail_title">价值观</p>
            <p>以用户为中心</p>
            <p>以奋斗者为本</p>
            <p>长期坚持艰苦奋斗</p>
          </li>
        </ul>
      </div>
      <div class="office">
        <div class="word">
          <p class="title">我们的办公室</p>
          <p>游戏无国界。无论玩家住在何处，</p>
        </div>
        <div class="background">
          <p>我们的团队都会尽力保证他们得到最佳的游戏体验</p>
          <div class="picture">
            <div>
              <img
                class="cat"
                src="../../assets/image/mobile/index/us1.png"
                alt=""
              >
              <img
                class="env"
                src="../../assets/image/mobile/index/us3.png"
                alt=""
              >
            </div>
            <div>
              <img
                class="people"
                src="../../assets/image/mobile/index/us2.png"
                alt=""
              >
              <img
                class="game"
                src="../../assets/image/mobile/index/us4.png"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '../modules/Head.vue'
import Foot from '../modules/Foot.vue'
export default {
  name: 'AboutUs',
  components: { Head, Foot }
}
</script>

<style scoped>
.main {
  padding-top: 3.575rem;
}
.main .detail_top {
  width: 100%;
  height: 26.25rem;
  background: url('../../assets/image/mobile/index/pic3.png') no-repeat;
  background-size: 23.4375rem 26.25rem;
  text-align: center;
  font-weight: 700;
  color: #fff;
}
.detail_top .en {
  display: block;
  font-size: 1.6875rem;
  line-height: 1.6875rem;
  opacity: 0.76;
  padding-top: 52%;
}
.en p {
  margin: 0;
}
.detail_top .zh {
  font-size: 1.375rem;
  line-height: 1.75rem;
  margin-top: 1.5625rem;
}

.detail_middle {
  padding: 0 1.0625rem;
  padding-top: 2.9375rem;
  background-color: #fff;
  color: #292826;
}
.detail_middle .our-story {
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.625rem;
  text-align: center;
  margin-top: 0;
}
.detail_middle .content {
  margin-top: 1.8rem;
  padding-bottom: 1.9375rem;
}
.detail_middle .content p {
  text-indent: 2em;
  font-size: 0.8125rem;
  color: #292826;
  line-height: 1.25rem;
  font-family: MicrosoftYaHei;
}
.detail_hope .our-hope {
  font-size: 2rem;
  line-height: 2.625rem;
  font-weight: bold;
  color: #292826;
  margin: 0;
  padding: 1.5rem 0 1.5rem 3.8rem;
  background-color: #e7e6e3;
}
.detail_hope .hope {
  width: 100%;
  height: 10.5625rem;
  background: url('../../assets/image/mobile/index/gy-1.png') no-repeat;
  background-size: 100% 100%;
}
.detail_hope .mission {
  margin-top: -0.1rem;
  width: 100%;
  height: 10.5625rem;
  background: url('../../assets/image/mobile/index/gy-2.png') no-repeat;
  background-size: 100% 100%;
}
.detail_hope .values {
  margin-top: -0.1rem;
  width: 100%;
  height: 10.5625rem;
  background: url('../../assets/image/mobile/index/gy-3.png') no-repeat;
  background-size: 100% 100%;
  padding-top: 2.5rem;
}
.detail_hope ul li {
  padding-top: 3.2rem;
}
.detail_hope p {
  padding-left: 3.5rem;
  font-size: 0.8125rem;
  color: #fff;
  margin: 0;
}
.detail_hope .detail_title {
  font-size: 1.6875rem;
  font-weight: bold;
  line-height: 2.75rem;
  color: #fff;
  margin-bottom: 0;
}
.office {
  width: 100%;
  height: 50rem;
  background-color: #e7e6e3;
  background-size: 100% 100%;
  background-position: -3.6rem 0.625rem;
  text-align: center;
}
.office .word {
  padding-top: 2.375rem;
  color: #292826;
}
.office .word p {
  font-size: 0.8125rem;
  font-family: Micrisift YaHei;
}
.office p {
  font-size: 0.8125rem;
  font-family: Micrisift YaHei;
}
.office .word .title {
  font-size: 2rem;
  font-weight: bold;
}
.background {
  background: url('../../assets/image/mobile/index/qk.png') no-repeat;
  background-size: contain;
}
.office .picture {
  display: flex;
  justify-content: space-between;
}
.picture .cat {
  margin-top: 7.0625rem;
  width: 10.75rem;
  margin-right: 0.6rem;
}
.picture .people {
  margin-top: 3.75rem;
  width: 11.9375rem;
}
.picture .env {
  width: 9.875rem;
  margin-top: 4rem;
}
.picture .game {
  width: 11.9375rem;
  margin-top: 5.625rem;
}
</style>
