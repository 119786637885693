<template>
  <div class="foot">
    <div class="line-h" />
    <h5 class="first-con">
      <span @click="us">关于我们</span>
      <span @click="cooperate">商务合作</span>
      <span @click="contact">联系我们</span>
    </h5>
    <h5 class="second-con">
      <span @click="protocol">用户协议</span>
      <span @click="privacy">隐私政策</span>
      <span @click="children">未成年人隐私保护指引</span>
      <span @click="guardianship"> 家长监护</span>
    </h5>
    <div class="advice">
      <p>健康游戏忠告：</p>
      <p>抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当</p>
      <p>适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活</p>
    </div>
    <div class="else">
      <p>
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
        >网站备案号：沪ICP备14010092号-7</a>
      </p>
      <p>
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502014068"
          target="_blank"
        >沪公网安备 31011502014068号</a>
      </p>
      <p>本平台游戏适合18岁以上人群</p>
      <p>沪B2-20140044</p>
      <p>上海域起网络科技有限公司 版权所有</p>
    </div>
    <div class="logo">
      <!-- <a
        href="http://sq.ccm.gov.cn/ccnt/sczr/service/business/emark/toDetail/96f03d33ac1a4311bbb134cb72b4045b"
        target="_blank"
      ><img
        class="wenhua-img"
        src="../../assets/image/mobile/index/wenhuajingying.png"
      ></a> -->
      <div class="left">
        <a
          target="_blank"
        ><img
          class="youxi-img"
          src="../../assets/image/mobile/index/youxidzbq.png"
        ></a>
        <a
          id="_xinchacharenzheng_cert_vip_kexinweb"
          style="text-decoration: none"
          target="_blank"
          href="https://xyt.xinchacha.com/pcinfo?sn=501547271763136512&certType=6"
        >
          <img
            class="kexin-img"
            src="../../assets/image/mobile/index/kexin.png"
          ></a>
        <a
          href="https://xinyong.yunaq.com/certificate?domain=www.gg.com&from=label&code=90030"
          target="_blank"
        ><img
          class="hangye-img"
          src="../../assets/image/mobile/index/hangye.png"
        ></a>
        <a
          @click="resistrumors"
        ><img
          class="resistrumors"
          src="../../assets/image/pc/shanghaiwangjingLogoV21.png"
        ></a>
      </div>
      <div class="right">
        <span>
          <img
            class="logo-img"
            src="../../assets/image/mobile/index/logWhite.png"
          >
        </span>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  methods: {
    us() {
      this.$router.push({
        path: '../about.html'
      })
    },
    cooperate() {
      this.$router.push({
        path: '../Cooperation'
      })
    },
    contact() {
      this.$router.push({
        path: '../Contact'
      })
    },
    protocol() {
      this.$router.push({
        path: '../protocol.html'
      })
    },
    children() {
      this.$router.push({
        path: '../child.html'
      })
    },
    privacy() {
      this.$router.push({
        path: '../privacy.html'
      })
    },
    guardianship() {
      this.$router.push({
        path: '../guardianship.html'
      })
    },
    resistrumors() {
      this.$router.push({
        path: '../resistrumors.html'
      })
    }
  }
}
</script>

<style scoped>
.foot {
  background-color: #000;
  text-align: left;
  padding-left: 1.125rem;
  font-size: 0.8125rem;
}
h5 {
  color: #fff;
  font-weight: 400;
  font-size: 0.8125rem;
}
a {
  color: #fff;
}
.first-con {
  margin: 1.5rem 0 0.3125rem 0;
}
.second-con {
  margin: 0 0 0.9375rem;
}
span {
  margin: 0.3rem;
}
span:first-child {
  margin-left: 0;
}
.advice {
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #7b7b7b;
}
.advice p {
  font-size: 0.8125rem;
}
.else {
  margin-top: 0.875rem;
  font-size: 0.8125rem;
}
.else p {
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #858585;
}
.else p a {
  color: #858585;
}
.line-h {
  width: 21rem;
  border-bottom: 0.125rem solid #212121;
  padding-top: 1.25rem;
}
/* .logo img {
  opacity: 0.76;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
}
.logo img:hover {
  filter: sepia(0%);
} */
.logo {
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 20px 10px 10px 10px;
}
.logo .left{
  display:  flex;
  flex-wrap: wrap;
}
.logo .left a{
  margin-right: 20px;
}
.logo .right{
  display: flex;
  margin: 0;
  flex-direction: column;
  justify-content: flex-end;

}
.youxi-img,
.hangye-img,
.kexin-img {
  opacity: 0.76;
}
/* .wenhua-img {
  width: 1.875rem;
  height: 1.875rem;
  margin: 2rem 0.5625rem 1.0625rem 0;
} */
.youxi-img {
  width: 1.5rem;
  /* height: 1.75rem; */
  /* margin: 2.125rem 0.6875rem 1.0625rem 0; */
}
.hangye-img {
  width: 4.5625rem;
  /* height: 1.5625rem; */
  /* margin: 2.1875rem 0 1.25rem 0; */
}
.kexin-img {
  width: 4.25rem;
  /* height: 1.5625rem; */
  /* margin: 2rem 0.5625rem 1.125rem 0; */
}
.resistrumors {
  width: 30px;
  margin-top: -10px;
}
.logo-img {
  width: 3.1875rem;
  /* margin: 0.4063rem 1.25rem 1.25rem 6.2rem; */
  /* opacity: 0.64; */
}
</style>
